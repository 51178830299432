import React from "react"
import dynamic from "next/dynamic"
import PropTypes from "prop-types"
import classNames from "classnames"

// ---------------------------------------------------------

import Image from "@components/image"

// ---------------------------------------------------------

const Button = dynamic(() => import("@components/button"), { ssr: false })

// ---------------------------------------------------------

import {
  quote_container,
  logo_container,
  byline,
  byline_centered,
  quote_text,
  quote_attribution,
  quote_profile_image,
  quote_button,
  font_paragraph,
  font_h1,
  font_h2,
  font_h3,
  font_h4,
  font_h5,
  font_label,
  font_default_large,
  font_default_small
} from "./styles.module.scss"

const fontStyleOptions = {
  default: font_paragraph,
  h1: font_h1,
  h2: font_h2,
  h3: font_h3,
  h4: font_h4,
  h5: font_h5,
  label: font_label
}
const Quote = ({
  quote,
  attribution,
  logo,
  image,
  backgroundImage,
  backgroundColor,
  buttonLink,
  buttonLabel,
  styling
}) => {
  const classes = classNames(quote_container, {
    [`background_${backgroundColor}`]: backgroundColor
  })

  const charCount = quote?.length
  let defaultFontStyle
  if (!styling) {
    defaultFontStyle = charCount > 150 ? font_default_small : font_default_large
  }

  const quoteLogo =
    logo && logo.src ? (
      <div className={logo_container}>
        <Image {...logo} alt={logo.alt} sizes="100vw" />
      </div>
    ) : null

  const quoteProfileImage =
    image && image.src ? (
      <div className={quote_profile_image}>
        <Image {...image} alt={image.alt} width="60" height="60" sizes="100vw" />
      </div>
    ) : null

  const buttonTheme =
    backgroundColor === "anvil" ||
    backgroundColor === "lagoon" ||
    backgroundColor === "off-black" ||
    backgroundColor === "midnight-gradient" ||
    backgroundColor === "dusk-gradient" ||
    backgroundColor === "deepest-pink-gradient"
      ? "arrow-horizontal-light"
      : "arrow-horizontal-dark"

  const quoteButton = (
    <Button
      theme={buttonTheme}
      className={quote_button}
      title={buttonLabel}
      labelAttr={buttonLabel}
      url={buttonLink}
    >
      {buttonLabel}
    </Button>
  )

  return (
    <div
      data-testid="quote"
      className={classes}
      style={
        backgroundImage
          ? {
              backgroundImage: `url(${backgroundImage})`
            }
          : null
      }
    >
      <blockquote className={quote_text}>
        <p
          className={classNames(defaultFontStyle, {
            [fontStyleOptions[styling]]: fontStyleOptions[styling]
          })}
        >
          {quote}
        </p>
        {attribution && (
          <div className={quote_attribution}>
            {quoteProfileImage || quoteLogo}
            <div className={image || logo ? byline : byline_centered}>
              <p>{attribution}</p>
              {buttonLink && buttonLabel && quoteButton}
            </div>
          </div>
        )}
      </blockquote>
    </div>
  )
}

Quote.propTypes = {
  /**
   * Text rendered as the pull quote.
   */
  quote: PropTypes.string.isRequired,

  /**
   * Text rendered as the name/company the quote is attributed to.
   */
  attribution: PropTypes.string,

  /**
   * Logo for quote attribution. May be overriden by `image` prop.
   */
  logo: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string,
    width: PropTypes.number
  }),

  /**
   * Typically the profile photo of the person being quoted. Takes precedence over the logo.
   */
  image: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string,
    width: PropTypes.number
  }),

  /**
   * Source URL for background image. When specified, overrides `backgroundColor` prop.
   */
  backgroundImage: PropTypes.string,

  /**
   * Specifies the quote background color.
   */
  backgroundColor: PropTypes.oneOf([
    "anvil",
    "cement",
    "ice",
    "lagoon",
    "limoncello",
    "off-black",
    "transparent",
    "ice-light",
    "ice-gradient",
    "ice-gradient-reversed"
  ]),

  /**
   * URL which the quote links to.
   */
  buttonLink: PropTypes.string,

  /**
   * Text of the link.
   */
  buttonLabel: PropTypes.string,

  /**
   * Styles the main text as header, label, or paragraph.
   */
  styling: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "label", "paragraph"])
}

Quote.defaultProps = {
  backgroundColor: "lagoon"
}

export default Quote
