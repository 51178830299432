import { ReactSVG } from "react-svg"
import React, { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

// ---------------------------------------------------------

import { parameterize } from "@lib/utils"

import Button from "../../button"
import ContentBlock from "../../content"

// ---------------------------------------------------------

import {
  accordion_item,
  accordion_header,
  dropdown_icon,
  dropdown_open,
  accordion_panel,
  active,
  closed,
  wrapper,
  arrow,
  has_content,
  light_text
} from "./styles.module.scss"

// ---------------------------------------------------------

const AccordionItem = ({ button, children, content, darkBackground, label, title }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen)
  }

  const buttonTheme = button && button.theme.toLowerCase().replace(" ", "-")
  const hasContent = children || content || button

  return (
    <div className={accordion_item}>
      <div
        className={classNames(
          accordion_header,
          darkBackground ? light_text : null,
          hasContent ? has_content : null
        )}
        onClick={toggleAccordion}
        onKeyDown={toggleAccordion}
        tabIndex={0}
        role="button"
      >
        <div>
          {label && <h6>{label}</h6>}
          <h4>{title}</h4>
        </div>
        {hasContent && (
          <ReactSVG
            src={`/icons/angle-right.svg`}
            className={classNames(dropdown_icon, {
              [dropdown_open]: isAccordionOpen
            })}
          />
        )}
      </div>
      <div
        className={classNames(
          wrapper,
          { [active]: isAccordionOpen },
          { [closed]: !isAccordionOpen }
        )}
      >
        {hasContent && (
          <div className={accordion_panel}>
            {children}
            {content && <ContentBlock content={content}></ContentBlock>}
            {button && (
              <Button
                {...button}
                theme={parameterize(buttonTheme)}
                className={classNames({ [arrow]: buttonTheme.includes("arrow") })}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

AccordionItem.propTypes = {
  /**
   * Title of the AccordionItem
   */
  title: PropTypes.string,

  /**
   * Specifies the text coming from the CMS (similar to children - do not alter manually)
   */
  content: PropTypes.object,

  /**
   * Call to action
   */
  button: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
    labelAttr: PropTypes.string,
    theme: PropTypes.string
  }),

  /**
   * Displayed above the title.
   */
  label: PropTypes.string,

  /**
   * passed in from Accordion parent, toggles text color
   */
  darkBackground: PropTypes.bool
}

export default AccordionItem
