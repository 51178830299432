import PropTypes from "prop-types"

// ---------------------------------------------------------

import Image from "@components/image"
import Grid from "@components/grid"
import fixtures from "./fixtures"

// ---------------------------------------------------------

import {
  card_container,
  card_description,
  container,
  image_desktop,
  image_mobile
} from "./styles.module.scss"

// ---------------------------------------------------------

const Platform = () => {
  const formattedCards =
    fixtures?.cards &&
    fixtures?.cards?.map((card, i) => {
      return (
        <div key={i} className={card_container}>
          <div className={image_mobile}>
            <Image {...card.image} alt={card.image.alt} sizes="50vw" />
          </div>
          <div className={card_description}>
            <h4>{card.title}</h4>
            <p>{card.description}</p>
          </div>
        </div>
      )
    })

  return (
    <section className={container}>
      <h6>Platform Overview</h6>
      <Grid column={3} paddingTop="small" paddingBottom="small">
        {formattedCards}
      </Grid>
      <Grid className={image_desktop} column={1} paddingTop="none" paddingBottom="large">
        <div>
          <Image
            src="https://images.ctfassets.net/5vqt5w81x2im/6dPAu3pYzYum56awPUty1o/96c1726eccb0cb5f568d300edb7bbd7b/Product_FlowDiagram_Desktop.png"
            alt="Diagram of raw messy data flowing into to an identity resolution graph, into a database, and translating to benefits in business value: increase in CLV, retention, and loyalty, and a decrease in acquisition cost."
            width={1440}
            height={800}
            sizes="(min-width: 1440px) 1440px, 100vw "
          />
        </div>
      </Grid>
    </section>
  )
}

Platform.propTypes = {
  /**
   * Content blocks that display in the platform section.
   */
  cardsCollection: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default Platform
